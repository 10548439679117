
// Register sub-components manually, for Nuxt doesn't register
// them automatically because <NederlandZingtCard> is a functional component
import Vue from 'vue'

Vue.component('NederlandZingtCardArticle', () => import('./Article.vue'))
Vue.component('NederlandZingtCardDefault', () => import('./Default.vue'))
Vue.component('NederlandZingtCardEpisode', () => import('./Episode.vue'))
Vue.component('NederlandZingtCardProgram', () => import('./Program.vue'))
Vue.component('NederlandZingtCardPlaylist', () => import('./Playlist.vue'))

export default {
  name: 'NederlandZingtCard',
  functional: true,
  props: {
    /**
     * The data content of the card
     */
    publication: {
      type: Object,
      default: null,
    },
  },
  render (createElement, { props, data, children }) {
    if (!props.publication) {
      return
    }

    let dynamicTag = 'nederland-zingt-card-default'
    switch (props.publication.modelId) {
      case '9ad0aecf-f7f9-431c-9193-52bc5bc22971':
        dynamicTag = 'nederland-zingt-card-article'
        break
      case 'fa9af868-5af3-49db-8b55-ff1155b2a53b':
      case 'a45ca4c5-4010-4608-8174-94354e618901':
        dynamicTag = 'nederland-zingt-card-program'
        break
    }
    if (props.publication.type === 'BROADCAST') {
      dynamicTag = 'nederland-zingt-card-episode'
    }
    if (props.publication.mid && props.publication.mid.includes('POMS_S_EO')) {
      dynamicTag = 'nederland-zingt-card-playlist'
    }
    if (props.publication.mid && props.publication.mid.includes('WO_EO')) {
      dynamicTag = 'nederland-zingt-card-song'
    }

    return createElement(
      dynamicTag,
      {
        props,
        ...data,
      },
      children,
    )
  },
}
