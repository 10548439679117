
import { metaDataMixin, notificationsMixin } from '~/mixins'

const VIDEO_PLAYLIST_ID = '904b2793-d48f-4231-bfc1-21f3b13374e0'

export default {
  mixins: [
    metaDataMixin,
    notificationsMixin,
  ],
  async asyncData({ $api, $config }) {
    const [
      { data: { items: articles = [] } = {} },
      { data: { items: mostLikedSongs = [] } = {} },
      { data: { items: callToActions = [] } = {} },
      { data: { item: playlist } = {} },
    ] = await Promise.all([
      // Get latests articles
      $api.articles.getAll({
        domain: $config.preprEnvironmentDomain,
        limit: 10,
      }),
      // Get most liked songs
      $api.recommendations.getPopular({
        domain: $config.preprEnvironmentDomain,
        events: 'Like',
        resource: 'songs',
        publishedAfter: '2022-01-01T00:00:00.000Z',
        limit: 12,
      }),
      // Get call to actions
      $api.calltoactions.getAll({
        domain: $config.preprEnvironmentDomain,
        limit: 2,
      }),
      // Get video playlist
      $api.playlists.getOne(VIDEO_PLAYLIST_ID, {}),
    ])
    
    return {
      articles,
      mostLikedSongs: mostLikedSongs,
      callToActions: callToActions.map(cta => cta.media?.url ? {...cta, media: cta.media.url} : cta),
      playlist: playlist ? playlist : null,
    }
  },
  data () {
    return {
      articles: [],
      mostLikedSongs: [],
      hasMore: true,
      isMounted: false,
      playlist: null,
      callToActions: [],
    }
  },
  async fetch () {
    const showAtLeast = 16
    const limit = this.articles.length < showAtLeast ? showAtLeast - this.articles.length : 15
    const offset = this.articles.length
    const { data: { items: articles = [], meta: { total = 0 } } } = await this.$api.articles.getAll({
      domain: this.$config.preprEnvironmentDomain,
      offset,
      limit,
    })

    this.articles.push(...articles)
    this.hasMore = total > this.articles.length
  },
  computed: {
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
    hasSubscriptions () {
      return this.notificationSubscriptions.length > 0
    },
  },
  mounted () {
    this.isMounted = true
  },
}
